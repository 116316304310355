import React from "react";
import { Outlet } from "react-router-dom";
// import Header from "./Components/Header/Header";
import Header from "./Components/Header/Header";
// import Footer from "./Components/Footer/Footer";
import styles from "./App.module.css";

function App() {
  return (
    <div className={styles.app}>
      <Header />
      <Outlet />
      {/* <Footer/> */}
    </div>
  );
}

export default App;
