import React from "react";
import styles from "./Header.module.scss";
import x from "../../assets/images/twitter.png";

const NewHeader = () => {
  return (
    <div className={styles.header}>
      <div></div>
      <a
        href="https://twitter.com/runestone_gold"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={x} alt="twitter" className={styles.logo} />
      </a>
    </div>
  );
};

export default NewHeader;
