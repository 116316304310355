import { SetStateAction, useEffect, useRef, useState } from "react";
import styles from "./Home.module.scss";
import MainContainer from "../../Components/UI/MainContainer/MainContainer";
// import mainLogo from "../../assets/images/mainLogo.svg";
// import title from "../../assets/images/whitelist-checker-title.png";
import subtitle from "../../assets/images/whitelist-checker-subtitle.png";
import stoneBitcoin from "../../assets/stone-bitcoin.jpg";
import stoneSolana from "../../assets/stone-solana.jpg";
import { Button, Input, Text } from "@chakra-ui/react";
import { whiteList } from "../../constants/whiteList";
import { whiteList2 } from "../../constants/whiteList2";
import { whiteList3 } from "../../constants/whiteList3";
import { whiteList4 } from "../../constants/whiteList4";
import { useTimer, padZero } from "../../Utils/utils";

const Home = () => {
  const [inputAddress, setInputAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tokenWhitelisted, setTokenWhitelisted] = useState<boolean | null>(
    null
  );

  const checkTokenWhitelistAddress = async () => {
    const isWhiteList = whiteList.filter(
      (address) =>
        inputAddress.toLowerCase().trim() === address.toLowerCase().trim()
    );
    const isWhiteList2 = whiteList2.filter(
      (address) =>
        inputAddress.toLowerCase().trim() === address.toLowerCase().trim()
    );

    const isWhiteList3 = whiteList3.filter(
      (address) =>
        inputAddress.toLowerCase().trim() === address.toLowerCase().trim()
    );

    const isWhiteList4 = whiteList4.filter(
      (address) =>
        inputAddress.toLowerCase().trim() === address.toLowerCase().trim()
    );
    if (
      isWhiteList.length ||
      isWhiteList2.length ||
      isWhiteList3.length ||
      isWhiteList4.length
    ) {
      setTokenWhitelisted(true);
    } else {
      setTokenWhitelisted(false);
    }
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setInputAddress(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    checkTokenWhitelistAddress();
    setIsLoading(false);
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // 当组件加载完毕后尝试播放视频
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Video play failed:", error);
      });
    }
  }, []); // 确保只在组件挂载后执行一次

  const launchCountdown = useTimer({
    deadline: new Date("2024-04-24T22:00:00+08:00"), //北京时间
  });

  return (
    <div className={styles.home}>
      <MainContainer className={styles.homeContainer}>
        {/* <video
          width="320"
          height="240"
          playsInline
          autoPlay
          loop
          muted
          ref={videoRef}
        >
          <source src={require("../../assets/Logo.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}

        <div className={styles.countDownContainer}>
          {" "}
          {!launchCountdown.timeIsDue && (
            <section className={styles.countDown}>
              <h2>Mint Start In</h2>
              <div className={styles.countDownTimeWrapper}>
                {!!launchCountdown.days && (
                  <div className={styles.countDownTickWrapper}>
                    <p>{launchCountdown.days}</p>
                  </div>
                )}
                {!!launchCountdown.days && <p>D</p>}
                <div className={styles.countDownTickWrapper}>
                  <p>{padZero(launchCountdown.hours)}</p>
                  <p>H</p>
                </div>
                <div className={styles.countDownTickWrapper}>
                  <p>{padZero(launchCountdown.minutes)}</p>
                  <p>M</p>
                </div>
                <div className={styles.countDownTickWrapper}>
                  <p>{padZero(launchCountdown.seconds)}</p>
                  <p>S</p>
                </div>
              </div>
            </section>
          )}
        </div>
        <div className={styles.mintWrapper}>
          <div className={styles.mintInnerWrapper}>
            <img src={stoneBitcoin} alt="bitcoin" />
            <a
              href="https://ordzaar.com/marketplace/collections/goldstone"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <Button
                className={styles.button}
                variant="outline"
                color={"white"}
              >
                Mint on Bitcoin
              </Button>
            </a>
          </div>
          <div className={styles.mintInnerWrapper}>
            <img src={stoneSolana} alt="solana" />
            <a
              href="https://launchmynft.io/sol/4155"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <Button
                className={styles.button}
                variant="outline"
                color={"white"}
              >
                Mint on Solana
              </Button>
            </a>
          </div>
        </div>
        <img
          src={subtitle}
          alt="enter your address to check"
          style={{ maxWidth: isMobile ? "100%" : "100%", height: "30px" }}
        />
        <div className={styles.inputWrapper}>
          <Input
            placeholder="Ordinals Wallet"
            _placeholder={{ color: "white" }}
            value={inputAddress}
            onChange={handleChange}
            style={{ color: "white" }}
          />
          <Button
            variant="outline"
            color={"white"}
            onClick={handleSubmit}
            isLoading={isLoading}
            className={styles.button}
          >
            CHECK
          </Button>
        </div>
        <div>
          {tokenWhitelisted !== null && (
            <Text color="white" whiteSpace="nowrap" fontSize={"24px"}>
              {tokenWhitelisted
                ? "Congratulations! You're whitelisted for the GOLD•RUNE•STONE token airdrop and Genesis NFT mint."
                : "You are not on the whitelist, sorry!"}
            </Text>
          )}
        </div>
      </MainContainer>
    </div>
  );
};

export default Home;
